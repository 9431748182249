import React, { useState, useEffect } from "react";
import * as pdfLogo from "../../asset/image/pdf_1.png";
import "../../App.scss";
import {
    SignatureResponse,
    SigningControllerApi,
    SigningInfoResponse,
    WorklistPersonResponse
} from "@digital-signer-gw/digital-signer-gw-ts-react";
import { useSelector } from "react-redux";

import signpdf from '@signpdf/signpdf';
import { P12Signer } from '@signpdf/signer-p12';
import PDFViewer from "./component/PDFViewer";
import FileSignerInfo from "./component/FileSignerInfo";
import { Input, Modal, Spin } from "antd";
import { messageError } from "../share/AlertModal";
import RejectModal from "../share/RejectModal";
import SignerStepper from "./component/SignerStepper";
import Header from "../share/Header";
import { format, set } from 'date-fns';
import PDFView from "./component/PDFView";
import SignPage from "./component/SignPage";
import { useNavigate } from "react-router-dom";
import SideBar from "../share/SideBar";
import { Content, Footer, SidebarWrapper } from "../../asset/style";
import BreadCrumbCustom from "../share/BreadCrumbCustom";
import WorklistPerson from "./component/WorklistPerson";
import * as FileSaver from 'file-saver';
import ThankyouModal from "../share/ThankyouModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import HTMLZone from "../share/HTMLZone";
import ApproveModal from "../share/ApproveModal";
import {logError} from "../../errorLog";

const DigitalSignature = () => {

    const [init, setInit] = useState(0);
    const [error, setError] = useState(false);
    const [pdfFile, setPdfFile] = useState<any>();
    const tokenState = localStorage.getItem("IdToken") as any;
    const viewerToken = localStorage.getItem("viewerToken") as string;
    const signingControllerApi: SigningControllerApi = new SigningControllerApi();
    const [apiInfoData, setApiInfoData] = useState<SigningInfoResponse | undefined>(undefined);
    const [fileSignatureInfo, setFileSignatureInfo] = useState<Array<SignatureResponse> | undefined>(undefined);
    const [signerPerson, setSignerPerson] = useState<Array<WorklistPersonResponse> | undefined>(undefined)
    const [reviewrPerson, setReviewerPerson] = useState<Array<WorklistPersonResponse> | undefined>(undefined)
    const [showForm, setShowForm] = useState(true);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [visibleRejectModal, setVisibleRejectModal] = useState<boolean>(false);
    const [visibleApproveModal, setVisibleApproveModal] = useState<boolean>(false);
    const [date, setDate] = useState<Date>(new Date());
    const [openPdfView, setOpenPdfView] = useState<boolean>(false);
    const [filePdf, setFilePdf] = useState<string>()
    const [spinning, setSpinning] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem("isAdmin");
    const [statePage, setStatePage] = useState<string>('MAIN');
    const [thankyouModal, setSetThankyouModal] = useState<boolean>(false);
    const [readPageOnly, setReadPageOnly] = useState<boolean>(false);
    const [succesMessage, setSuccesMessage] = useState<any>(null);
    const [rejectMessage, setRejectMessage] = useState<any>(null);
    const [bodyThankyou, setBodyThankyou] = useState<any>('');
    useEffect(() => {
        if (tokenState !== null) {
            if (viewerToken) {
                setReadPageOnly(true);
                getLinkViewerInfo();
            } else {
                getInfo();
            }
        }

    }, [tokenState]);

    useEffect(() => {
        if (init < 2) {
            setSpinning(true)
        } else {
            setSpinning(false)
        }
        if (error) {
            setSpinning(false)
        }
    }, [init, error])
    useEffect(() => {
        sessionStorage.setItem('statePage', statePage);
    }, [statePage]);


    const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    const handleReject = (value: any) => {
        const { rejectReason } = value;
        setVisibleRejectModal(false)
        setSpinning(true)
        signingControllerApi.rejectUsingPOST(rejectReason, tokenState).then((response: any) => {
            setSpinning(false)
            setBodyThankyou('ได้รับคำขอการยกเลิกสัญญาเรียบร้อยแล้ว')
            setSetThankyouModal(true);

            setTimeout(() => {
                setSetThankyouModal(false);
                setRejectMessage('Rejected');
            }, 2000);
        }).catch((e: any) => {
            setSpinning(false)
            messageError(e);
            logError(e);
        });
    }

    const handleApprove = () => {
        setVisibleApproveModal(false)
        setSpinning(true)
        signingControllerApi.approveUsingPOST(tokenState).then((response: any) => {
            setSpinning(false)
            setBodyThankyou('อนุมัติสัญญาเรียบร้อยแล้ว')
            setSetThankyouModal(true);
            setTimeout(() => {
                setSetThankyouModal(false)
                setSuccesMessage('Approved');
            }, 2000);
        }).catch((e: any) => {
            setSpinning(false)
            messageError(e);
            logError(e);
        });
    }

    const getInfo = () => {
        signingControllerApi.infoUsingGET(tokenState).then((response: any) => {
            setApiInfoData(response.data.data);
            let worklistPersonResult = response.data.data?.worklistPerson;
            setSignerPerson(worklistPersonResult?.filter((x: any) => x.signerRole == "SIGNER"));
            setReviewerPerson(worklistPersonResult?.filter((x: any) => x.signerRole == "REVIEWER"));
            setInit(p => p + 1)
            response.data.data.signingStatus !== "WAITING" && setReadPageOnly(true);
        }).catch((e: any) => {
            setError(true);
            setSpinning(false)
            messageError(e);
            logError(e);
        });
        signingControllerApi.getSignatureDetailUsingGET(tokenState).then((response: any) => {
            setFileSignatureInfo(response.data.data);
            setInit(p => p + 1)
        }).catch((e: any) => {
            setSpinning(false)
            logError(e);
        });

    };

    const getLinkViewerInfo = () => {
        signingControllerApi.infoByViewerTokenUsingGET(tokenState).then((response: any) => {
            setApiInfoData(response.data.data);
            let worklistPersonResult = response.data.data?.worklistPerson;
            setSignerPerson(worklistPersonResult?.filter((x: any) => x.signerRole == "SIGNER"));
            setReviewerPerson(worklistPersonResult?.filter((x: any) => x.signerRole == "REVIEWER"));
            setInit(p => p + 1)
            response.data.data.signingStatus !== "WAITING" && setReadPageOnly(true);
        }).catch((e: any) => {
            setError(true);
            setSpinning(false)
            messageError(e);
            logError(e);
        });
        signingControllerApi.getSignatureDetailByViewerTokenUsingGET(tokenState).then((response: any) => {
            setFileSignatureInfo(response.data.data);
            setInit(p => p + 1)
        }).catch((e: any) => {
            setSpinning(false)
            logError(e);
        });

    };

    const downLoadPdf = () => {
        setSpinning(true)
        let downloadApi;
        if (viewerToken !== null) {
            downloadApi = signingControllerApi.downloadOriginalBase64ByViewerTokenUsingGET(tokenState);
        } else {
            downloadApi = signingControllerApi.downloadOriginalBase64ByTokenUsingGET(tokenState);
        }
        downloadApi.then((response: any) => {
            const pdfFile = b64toBlob(response.data.data.data, response.data.data.fileType)
            FileSaver.saveAs(pdfFile, response.data.data.fileName);
            setSpinning(false)
        }).catch((e: any) => {
            setSpinning(false)
            messageError(e);
            logError(e);
        })
    }

    const showPdfView = () => {

        if (!pdfFile) {
            setSpinning(true);
            let downloadApi;
            if (viewerToken !== null) {
                downloadApi = signingControllerApi.downloadByViewerTokenUsingGET(tokenState, { responseType: 'blob' });
            } else {
                downloadApi = signingControllerApi.downloadByTokenUsingGET(tokenState, { responseType: 'blob' });
            }
            downloadApi.then((response: any) => {
                const file = new Blob(
                    [response.data as BlobPart],
                    { type: 'application/pdf' });
                setPdfFile(file);
                const fileURL = URL.createObjectURL(file);
                setFilePdf(fileURL)
                setOpenPdfView(true);
                setSpinning(false);
            }).catch((e: any) => {
                setSpinning(false)
                messageError(e);
                logError(e);
            });
        } else {
            setOpenPdfView(true);
        }


    };


    const handleDosigning = () => {
        setBodyThankyou('ลงนามเสร็จสมบูรณ์')
        setSetThankyouModal(true);
        setStatePage('MAIN');
        setTimeout(() => {
            setSetThankyouModal(false)
            setSuccesMessage('Signed');
        }, 2000);
    }

    return (
        <>
            {init >= 1 && <>
                {statePage == 'MAIN' && <div>
                    <Header date={format(date, 'yyyy-MM-dd')} signerRole={apiInfoData?.signerRoleName} />
                    <div className='d-flex'>
                        {!readPageOnly && <SidebarWrapper>
                            <WorklistPerson signerPerson={signerPerson} reviewerPerson={reviewrPerson} currentRole={apiInfoData?.signerRole} />
                        </SidebarWrapper>}

                        <div className='d-flex flex-column'>
                            <Content readonly={readPageOnly}>
                                <BreadCrumbCustom item={[{ href: '/main', title: 'Home' }, { title: apiInfoData?.signingSubtitle }]} />
                                {!viewerToken && (
                                <h4 className="d-flex mt-2">{apiInfoData?.signerRoleName}: {apiInfoData?.signerName}</h4>
                                )}
                                    <div className="mb-2"></div>
                                {(succesMessage || apiInfoData?.signingStatus == 'APPROVED' || apiInfoData?.signingStatus == 'COMPLETED') && <div>
                                    <div className="card mx-5 mt-2 mb-2">
                                        <div className="card-body py-2">
                                            <div className="d-flex">
                                                <div className="me-2"><FontAwesomeIcon icon={faCheckCircle} color="#006600" /></div>
                                                <div className="d-flex flex-column" style={{ lineHeight: 1.2 }}>
                                                    {apiInfoData?.signingStatus == 'WAITING' && <div>{apiInfoData?.signerRole == "REVIEWER" ? "Approved" : "Signed"}</div>}
                                                    {apiInfoData?.signingStatus == 'APPROVED' && <div>Approved</div>}
                                                    {apiInfoData?.signingStatus == 'COMPLETED' && <div>Signed</div>}
                                                    <div>โดย {apiInfoData?.signerName}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>}
                                {(rejectMessage || apiInfoData?.signingStatus == 'REJECTED') && <div>
                                    <div className="card mx-5 mt-2 mb-2">
                                        <div className="card-body py-2">
                                            <div className="d-flex">
                                                <div className="me-2"><FontAwesomeIcon icon={faXmarkCircle} color="#dc3545" /></div>
                                                <div className="d-flex flex-column" style={{ lineHeight: 1.2 }}>
                                                    <div>Rejected by</div>
                                                    <div>โดย {apiInfoData?.signerName}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>}
                                <div>

                                    {(apiInfoData && showForm) && (
                                        <div className="card mx-5">
                                            <div className="card-body">
                                                <div className='d-flex'>
                                                    <div><h6>{apiInfoData.signingSubtitle}</h6></div>
                                                    <div className='ms-auto' >
                                                        {/* <div className="d-flex justify-content-center"><img src={pdfLogo.default} alt="pdfLogo" className="logo me-0 click" /></div>
                                                        <div>สัญญาฉบับเต็ม</div> */}
                                                        <button className="btn btn-link-2 btn-sm me-2" onClick={downLoadPdf}>Download เอกสาร</button>
                                                        <button className="btn btn-primary text-white btn-sm" onClick={showPdfView}>
                                                            <img src={pdfLogo.default} alt="pdfLogo" width={20} className="me-2" />
                                                            อ่านสัญญาฉบับเต็ม
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                            <hr className="my-0" />
                                            <div className="card-body">
                                                {apiInfoData?.messageItems && apiInfoData.messageItems.map((message: any, index: number) => {
                                                    return (
                                                        <p key={'message' + index}>
                                                            <strong>{message.messageLabel}</strong> : {message.messageValue}<br />
                                                        </p>
                                                    );
                                                })}
                                            </div>
                                            <hr className="my-0" />
                                            <div className="card-body">
                                                <p>รายละเอียดเพิ่มเติม</p>
                                                {apiInfoData.privateMessage && <HTMLZone className="font-sarabun" style={{ fontSize: '16px' }} htmlContent={apiInfoData.privateMessage as any} />}

                                                {/* <p className="font-sarabun" style={{ fontSize: '16px' }}>{apiInfoData.privateMessage}</p> */}
                                            </div>
                                        </div>
                                    )}

                                </div>
                                <br />
                            </Content>
                            <Footer>
                                {apiInfoData && <>
                                    <div className="d-flex justify-content-end gap-2">
                                        {apiInfoData.signerRole == "SIGNER" && (
                                            <button
                                                type="button"
                                                className="btn btn-primary text-white btn-sm"
                                                disabled={succesMessage || rejectMessage || readPageOnly}
                                                onClick={() => setStatePage("SIGN")}
                                            >
                                                <img src="/images/pdf.svg" width={15} className="me-2" alt="" />
                                                ลงนามเอกสาร
                                            </button>
                                        )}
                                        {apiInfoData.signerRole == "REVIEWER" && (
                                            <button
                                                type="button"
                                                disabled={succesMessage || rejectMessage || readPageOnly}
                                                className="btn btn-primary text-white btn-sm"
                                                onClick={() => setVisibleApproveModal(true)}
                                            >
                                                Approve
                                            </button>
                                        )}
                                        {!succesMessage && !rejectMessage && !readPageOnly && <button
                                            type="button"
                                            className="btn btn-danger btn-sm"

                                            onClick={() => setVisibleRejectModal(true)}
                                        >
                                            <img src="/images/file.svg" width={15} className="me-2" alt="" />
                                            Reject
                                        </button>}


                                    </div>
                                </>}

                            </Footer>
                        </div>
                    </div>


                </div>}
                {statePage == 'SIGN' && <SignPage
                    apiInfoData={apiInfoData}
                    pdfFile={pdfFile}
                    setStatePage={setStatePage}
                    handleDosigning={handleDosigning}
                    isAdmin={isAdmin == "true" ? true : false} />}
                <RejectModal
                    open={visibleRejectModal}
                    onCancel={() => setVisibleRejectModal(false)}
                    onOk={handleReject}
                />
                <ApproveModal
                    open={visibleApproveModal}
                    onCancel={() => setVisibleApproveModal(false)}
                    onOk={handleApprove}
                />
                <PDFView open={openPdfView} setOpen={setOpenPdfView}
                    fileSignatureInfo={fileSignatureInfo}
                    file={filePdf} />
                <ThankyouModal open={thankyouModal} body={bodyThankyou} />
            </>}

            <Spin spinning={spinning} fullscreen />
        </>

    );
};

export default DigitalSignature;

import { WorklistPersonResponse } from '@digital-signer-gw/digital-signer-gw-ts-react'
import { faAngleDown, faAngleLeft, faAngleUp, faCaretDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

type Props = {
    currentRole: string | undefined
    signerPerson: Array<WorklistPersonResponse> | undefined
    reviewerPerson: Array<WorklistPersonResponse> | undefined
}
const Title = styled.div`
    display: flex;
    align-items: center;
    font-size: calc(1vw + 1vh);
    .num{
        width: calc(1vw + 1vh);//24px;
        height: calc(1vw + 1vh);//24px;
        background-color: #0064C5;
        color: white;
        border-radius: 50%;
        text-align: center;
        line-height: 1;

    }
`
const Subtitle = styled.div`
    display: flex;
    align-items: start;
    font-size: calc(0.9vw + 0.9vh);
    .approve{
        min-width: calc(0.9vw + 0.9vh);
        width: calc(0.9vw + 0.9vh);//18px;
        height: calc(0.9vw + 0.9vh);//18px;
        margin-top: 5px;
        background-color: #006600;
        color: white;
        border-radius: 50%;
        text-align: center;
        line-height: 1.2;
    }
    .not-approve{
        min-width: calc(0.9vw + 0.9vh);
        width: calc(0.9vw + 0.9vh);//18px;
        height: calc(0.9vw + 0.9vh);//18px;
        margin-top: 5px;
        background-color: #9da6ad;
        color: white;
        border-radius: 50%;
        text-align: center;
        line-height: 1.2;
    }
`
const WorklistPerson = (props: Props) => {
    const [reviewerCollapse, setReviewerCollapse] = useState<boolean>(false);
    const [signerCollapse, setSignerCollapse] = useState<boolean>(false);

    useEffect(() => {
        console.log(props.currentRole)
        props.currentRole === 'REVIEWER' ? setReviewerCollapse(false) : setReviewerCollapse(true)
        props.currentRole === 'SIGNER' ? setSignerCollapse(false) : setSignerCollapse(true)
    }, [])

    return (
        <div>
            {props.reviewerPerson && props.reviewerPerson.length > 0 &&
                <Title className='mb-1'>
                    <span className='num'>1</span>
                    <span className='ms-2'>Review</span>
                    <div className='ms-auto'>
                        {reviewerCollapse ? <FontAwesomeIcon className='click' icon={faAngleUp} onClick={() => setReviewerCollapse(false)} /> : <FontAwesomeIcon className='click' icon={faAngleDown} onClick={() => setReviewerCollapse(true)} />}
                    </div>
                </Title>}
            {!reviewerCollapse &&
                <div className='container-scroll-worklist'>
                    {props.reviewerPerson && props.reviewerPerson.map((x, index) => (
                        <div key={'revReview' + index} style={{ backgroundColor: x.historyStatus == 'CURRENT' ? '#fef4e9' : 'white' }}>
                            <Subtitle className='ms-3 mb-1'>
                                <span className={x.historyStatus == "APPROVED" ? 'approve' : 'not-approve'}>{x.historyStatus == "APPROVED" && <FontAwesomeIcon size='xs' className='click' color='white' icon={faCheck} />}</span>
                                <div className='d-flex flex-column'>
                                    <div className='ms-2 text-bold'>{x.signerRoleName}</div>
                                    <div className='ms-2 text-secondary'><small>{x.personName}</small></div>
                                    <div className='ms-2 text-secondary'><small>{x.email}</small></div>
                                </div>
                            </Subtitle>

                        </div>
                    ))}
                </div>
            }
            {props.signerPerson && props.signerPerson.length > 0 &&
                <Title className='mb-1'>
                    <span className='num'>2</span>
                    <span className='ms-2'>Sign</span>
                    <div className='ms-auto'> {signerCollapse ? <FontAwesomeIcon className='click' icon={faAngleUp} onClick={() => setSignerCollapse(false)} /> : <FontAwesomeIcon className='click' icon={faAngleDown} onClick={() => setSignerCollapse(true)} />}</div>
                </Title>}
            {!signerCollapse &&
                <div className='container-scroll-worklist'>
                    {props.signerPerson && props.signerPerson.map((x, index) => (
                        <div key={'revSign' + index} style={{ backgroundColor: x.historyStatus == 'CURRENT' ? '#fef4e9' : 'white' }}>
                            <Subtitle className='ms-3 mb-1'>
                                <span className={x.historyStatus == "SIGNED" ? 'approve' : 'not-approve'}>{x.historyStatus == "SIGNED" && <FontAwesomeIcon size='xs' className='click' color='white' icon={faCheck} />}</span>
                                <div className='d-flex flex-column'>
                                    <div className='ms-2 text-bold'>{x.signerRoleName}</div>
                                    <div className='ms-2 text-secondary'><small>{x.personName}</small></div>
                                    <div className='ms-2 text-secondary'><small>{x.email}</small></div>
                                </div>
                            </Subtitle>
                        </div>
                    ))}
                </div>}
        </div>
    )
}

export default WorklistPerson

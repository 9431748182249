import {
    ErrorLogControllerApi
} from "@digital-signer-gw/digital-signer-gw-ts-react";
const errorLogControllerApi: ErrorLogControllerApi = new ErrorLogControllerApi();
const logError = (error: any) => {
    try {
        const errorString = typeof error === 'string' ? error : JSON.stringify(error, Object.getOwnPropertyNames(error));
        errorLogControllerApi.logErrorUsingPOST(errorString).then((response: any) => {});
    } catch (e) {
        console.log(e);
    }

};

export {logError};
